import React from "react";
import moment from 'moment';
import 'moment/locale/es';
import "./HistoryOCRDocumentsComponent.scss";
import "../../bdb-views/OCRAnalyzeView/OCRAnalyzeView.scss";
import { useDispatch } from "react-redux";
import { getHistoryFiles } from "../../bdb-actions/OCRAnalyzeActions";
import Swal from 'sweetalert2';
import { OCRLabelsTranslated, OCR_Analize_DOCS } from '../../../constants'

const handleResponseOCR = async (file) => {
  if (file?.status !== "SUCCEEDED") return;
  let tblHTML = '<table border="4" style="margin:auto;width: 80%;"><thead><tr><th>Pagina</th><th>Dato</th><th>Valor</th><th>Confianza</th><th>Confianza OCR</th></tr></thead><tbody id="table-body">';
  for (const element of JSON.parse(file.responseOCR)) {
      tblHTML = tblHTML + `<tr><td>${element.page}</td><td>${OCRLabelsTranslated[element.label] ? OCRLabelsTranslated[element.label] : element.label}</td><td>${element.text=="null"?"No se encontró": element.text}</td><td>${element.confidence}</td><td>${element.ocrConfidence}</td></tr>`
  }
  tblHTML = tblHTML + '</tbody></table>'

  await Swal.fire({
      title: `Datos extraidos para ${file?.viewName}:`,
      icon: 'info',
      html: tblHTML,
      showCloseButton: true,
      focusConfirm: false,
      confirmButtonText: 'Ok',
      confirmButtonColor: '#002c76',
      width: "80%"
  })
}

const classState = {
  PROCESSING: {
    label: "Procesando",
    class: "info",
  },
  FAILED: {
    label: "Fallido",
    class: "error",
  },
  SUCCEEDED: {
    label: "Exitoso",
    class: "success",
  },
};

const HistoryOCRDocumentsComponent = ({ files }) => {
    const dispatch = useDispatch();
    const handleRefresh = () => {
        dispatch(getHistoryFiles())
    }
  return (
    <section className="Insert_large_files_col" style={{marginTop: '15px'}}>
      <div
        style={{ position: "relative", marginTop: "15px", width: '84%' }}
      >
        <h3 className="historyFile-title">Historial</h3>
        <span className="historyFile-paragraph">
          Una vez cargados los documentos, podrás visualizar su estado de
          procesamiento y descargar el resultado del analisis.
        </span>
        <div className="historyFile-box-refresh" onClick={handleRefresh}>
          <div className="historyFile-refresh-button"></div>
          <span className="historyFile-text-refresh">Actualizar</span>
        </div>
      </div>
      <table className="historyFile-table">
        <thead style={{ display: 'block'}}>
        <tr>
          <th>Fecha de carga</th>
          <th>Tipo de documento</th>
          <th>Nombre</th>
          <th>Estado</th>
        </tr>

        </thead>
        <tbody style={{ display: 'block', height:'191px', overflowY: 'auto'}}>
        {files.map((file, idx) => (
          <tr key={idx}>
            <td>{moment(file?.createDate.split("#")[0]).local('es').format('DD-MMM-YYYY HH:mm')}</td>
            <td>{ OCR_Analize_DOCS.filter( (itm)=> itm.id === file?.onbaseType )[0].label}</td>
            <td>{file?.viewName}</td>
            <td>
              <div
                onClick={()=>handleResponseOCR(file)}
                className={`historyFile-box-tag-${
                  classState[file?.status]?.class
                }`}
              >
                {classState[file?.status]?.label}
              </div>
            </td>
          </tr>
        ))}

        </tbody>
      </table>
    </section>
  );
};

export default HistoryOCRDocumentsComponent;
